import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delay, retryWhen, take} from "rxjs/operators";

import { environment } from '../../../environments/environment';

import { User } from '../../models/user/User';
import {UserCreate} from '../../models/user/UserCreate';

@Injectable({
  providedIn: 'root',
})
export class UserServerService {

  private readonly URL_SERVER: string = environment.URL_BACKEND_SERVER;

  constructor(private readonly httpClient: HttpClient) { }

  post(resource: string, userCreate: UserCreate) {
    return this.httpClient.post<User>(this.URL_SERVER + resource, userCreate)
      .pipe(
        retryWhen(errors => errors.pipe(delay(10000), take(3)))
      );
  }

  put(resource: string, userChange: User) {
    return this.httpClient.put<User>(this.URL_SERVER + resource, userChange)
  }
}
