import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {delay, retryWhen, take} from "rxjs/operators";

import { environment } from '../../../environments/environment';

import { Login } from '../../models/login/Login';
import {LoginResponse} from '../../models/login/LoginResponse';

@Injectable({
  providedIn: 'root',
})
export class LoginServerService {

  private readonly URL_SERVER: string = environment.URL_BACKEND_SERVER;

  constructor(private readonly httpClient: HttpClient) { }

  post(resource: string, login: Login) {
    return this.httpClient.post<LoginResponse>(this.URL_SERVER + resource, login)
      .pipe(
        retryWhen(errors => errors.pipe(delay(10000), take(3)))
      );
  }
}
